import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import * as CalendarActions from './calendar.actions';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {BasicResponseModel} from '../../../../shared/models/basic-response.model';
import {environment} from '../../../../../environments/environment';
import {of} from 'rxjs';
import {EventModel} from '../../../../shared/models/calendar/eventModel';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {UserProfile} from '../../../../shared/models/user/user-profile';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../store/app.reducer';
import {CalendarService} from '../../../../core/services/calendar.service';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error && errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error && errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message ? errorRes.error.errors.message : 'An error occurred!';
    errors = errorRes.error.errors;
  }
  return of(new CalendarActions.CalendarFail({errorMessage, errors}));
};

@Injectable()
export class CalendarEffects {
  constructor(
    private store: Store<AppState.AppState>,
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private calendarServ: CalendarService
  ) {}
  @Effect()
  createStart = this.actions$.pipe(
    ofType(CalendarActions.CREATE_EVENT_START),
    switchMap((calendarData: CalendarActions.CreateEventStart) => {

      return this.http.post<BasicResponseModel>(
        environment.baseApiUrl + 'events/create',
        calendarData.payload.event
      ).pipe(
        map(
          resData => {
            return new CalendarActions.CreateEventSuccess({
              event: resData.success.data as EventModel,
              message: resData.success.message
            });
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect()
  getStart = this.actions$.pipe(
    ofType(CalendarActions.GET_EVENT_START),
    switchMap(() => {
      return this.http.get<BasicResponseModel>(
        environment.baseApiUrl + 'events'
      ).pipe(
        map(
          resData => {
            return new CalendarActions.GetEventSuccess({
              events: resData.success.data as EventModel[]
            });
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect()
  getPublicStart = this.actions$.pipe(
    ofType(CalendarActions.GET_PUBLIC_EVENT_START),
    switchMap((action: CalendarActions.GetPublicEventStart) => {
      return this.http.get<BasicResponseModel>(
        environment.baseApiUrl + 'events/public/all'
      ).pipe(
        map(
          resData => {
            return new CalendarActions.GetEventSuccess({
              events: resData.success.data as EventModel[]
            });
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect()
  getTrainingStart = this.actions$.pipe(
    ofType(CalendarActions.GET_TRAINING_WORKOUTS_START),
    switchMap((action: CalendarActions.GetTrainingWorkoutsStart) => {
      return this.http.get<BasicResponseModel>(
        environment.baseApiUrl + 'event/' + action.payload.id + '/students/recordings/get'
      ).pipe(
        map(resData => {
          return new CalendarActions.GetTrainingWorkoutsSuccess({eventReview: resData.success.data});
        }),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect()
  getUserStart = this.actions$.pipe(
    ofType(CalendarActions.GET_USERS_START),
    switchMap((calendarData: CalendarActions.GetUserStart) => {
      return this.http.post<BasicResponseModel>(
        environment.baseApiUrl + 'users/filter',
        {
          role: calendarData.payload.role
        }
      ).pipe(
        map(
          resData => {
            return new CalendarActions.GetUserSucces({
              users: resData.success.data as UserProfile[]
            });
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect()
  updateStart = this.actions$.pipe(
    ofType(CalendarActions.UPDATE_EVENT_START),
    switchMap((calendarData: CalendarActions.UpdateEventStart) => {
      return this.http.post<BasicResponseModel>(
        environment.baseApiUrl + 'events/' + calendarData.payload.event.id + '/update',
        calendarData.payload.event
      ).pipe(
        map(
          resData => {
            return new CalendarActions.UpdateEventSuccess({
              event: resData.success.data as EventModel,
              message: resData.success.message
            });
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect()
  deleteStart = this.actions$.pipe(
    ofType(CalendarActions.DELETE_START),
    switchMap((calendarData: CalendarActions.DeleteStart) => {
      return this.http.delete<BasicResponseModel>(
        environment.baseApiUrl + 'events/' + calendarData.payload.id + '/delete'
      ).pipe(
        map(
          resData => {
            return new CalendarActions.DeleteSuccess({
              id: resData.success.data.id,
              message: resData.success.message
            });
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect({dispatch: false})
  success = this.actions$.pipe(
    ofType(
      CalendarActions.CREATE_EVENT_SUCCESS,
      CalendarActions.UPDATE_EVENT_SUCCESS
    ),
    tap((action: {payload: {message}}) => {
      this.calendarServ.navigateBack();
      this.store.dispatch(new CalendarActions.Home({home: true}));
      this.snackBar.open(action.payload.message, null, { duration: 1500 });
    })
  );
  @Effect({dispatch: false})
  successDelete = this.actions$.pipe(
    ofType(
      CalendarActions.DELETE_SUCCESS
    ),
    tap((action: {payload: {message}}) => {
      this.snackBar.open(action.payload.message, null, { duration: 1500 });
    })
  );
  @Effect({dispatch: false})
  eventStarted = this.actions$.pipe(
    ofType(
      CalendarActions.EVENT_STARTED
    ),
    map((action: CalendarActions.EventStarted) => action.payload),
    withLatestFrom(this.store),
    map(([action, store]) => {
      if (store.calendar.startedEvents.includes(action.id)) {
        this.snackBar.open(action.message, 'Close', { duration: 5000 });
      }
    })
  );
}
